var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "timeSyncSection", staticClass: "time-sync-section" },
    [
      _c("div", { ref: "timeSyncCol", staticClass: "time-sync-col" }, [
        _c(
          "div",
          { staticClass: "time-sync-second-col" },
          [
            _c(
              "router-link",
              {
                staticStyle: { "min-width": "200px", margin: "auto 0" },
                attrs: { tag: "button", to: { name: "ContactUs" } },
              },
              [_vm._v(" Contact Us ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }