var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LandingHeader"),
      _c("LandingHero"),
      _c("LandingCarousel"),
      _c("LandingFeatures"),
      _c("LandingSchools"),
      _c("LandingCallout"),
      _c("LandingCallToAction"),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }