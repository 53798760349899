var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "featuresSection", staticClass: "features-section" },
    [
      _c("div", { ref: "featuresCol", staticClass: "features-col" }, [
        _vm._m(0),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-box" }, [
      _c("div", { staticClass: "col-one" }, [
        _c("h3", [_vm._v("Course Syncing")]),
        _c("p", [
          _vm._v(
            " Eliminate double data entry for teachers through automated data synchronization between Google Classroom™ and your SIS. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-two" }, [
        _c("img", {
          attrs: {
            src: "/images/landing/feature-one-image.png",
            alt: "feature",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-box" }, [
      _c("div", { staticClass: "col-one" }, [
        _c("h3", [_vm._v("Analytics")]),
        _c("p", [
          _vm._v(
            " Eliminate double data entry for teachers through automated data synchronization between Google Classroom™ and your SIS. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-two" }, [
        _c("img", {
          attrs: {
            src: "/images/landing/feature-two-image.png",
            alt: "feature",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-box" }, [
      _c("div", { staticClass: "col-one" }, [
        _c("h3", [_vm._v("Performance")]),
        _c("p", [
          _vm._v(
            " Innovative administrator accounts and school customization to efficiently support teachers. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-two" }, [
        _c("img", {
          attrs: {
            src: "/images/landing/feature-three-image.png",
            alt: "feature",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-box" }, [
      _c("div", { staticClass: "col-one" }, [
        _c("h3", [_vm._v("Control")]),
        _c("p", [
          _vm._v(
            " Innovative administrator accounts and school customization to efficiently support teachers. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-two" }, [
        _c("img", {
          attrs: {
            src: "/images/landing/feature-four-image.png",
            alt: "feature",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-box" }, [
      _c("div", { staticClass: "col-one" }, [
        _c("h3", [_vm._v("Report Cards")]),
        _c("p", [
          _vm._v(
            " Streamlined report card tools with teacher report card entry and administrator EGG file extraction "
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-two" }, [
        _c("img", {
          attrs: {
            src: "/images/landing/feature-five-image.png",
            alt: "feature",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }