<template>
<div>
    <!-- header new -->
    <LandingHeader />

    <!-- hero section -->
    <LandingHero />

    <!-- features section -->
    <LandingCarousel />

    <!-- <LandingFeaturesSlider /> -->
    <LandingFeatures />

    <!-- school teams section -->
    <LandingSchools />

    <!-- manage sections -->
    <LandingCallout />

    <!-- time sync section -->
    <LandingCallToAction />

    <!-- about section -->
    <!-- <LandingAbout /> -->

    <!-- footer-section -->
    <LandingFooter />
</div>
</template>

<script>

import LandingHeader from '../components/landing/LandingHeader.vue';
import LandingHero from '../components/landing/LandingHero.vue';
import LandingFeatures from '../components/landing/LandingFeatures.vue';
import LandingSchools from '../components/landing/LandingSchools.vue';
import LandingCallout from '../components/landing/LandingCallout.vue';
import LandingCallToAction from '../components/landing/LandingCallToAction.vue';
import LandingFooter from '../components/landing/LandingFooter.vue';
import LandingCarousel from '../components/landing/LandingCarousel.vue';

export default {
    name: 'LandingPage',
    components: {
        LandingHeader,
        LandingHero,
        LandingFeatures,
        LandingCarousel,
        LandingSchools,
        LandingCallout,
        LandingCallToAction,
        LandingFooter,
    },
    computed: {

    },
};

</script>
