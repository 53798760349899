<template>
<div ref="schoolTeamsSection" class="school-teams-section">
    <div ref="schoolTeamsCol" class="school-teams-col">
        <div class="first-col">
            <h2>School Teams</h2>
            <h3>Customize SyncGrades to best meet your unique needs.</h3>
            <p>
                Tailor your Sync Grades settings based on your grading system, school
                needs, and user preferences.
            </p>
        </div>
        <div class="second-col">
            <VueSlickCarousel v-bind="settings">
                <a href="/images/landing/Screenshot_100.png" target="_blank"><img src="/images/landing/Screenshot_100.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_101.png" target="_blank"><img src="/images/landing/Screenshot_101.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_102.png" target="_blank"><img src="/images/landing/Screenshot_102.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_103.png" target="_blank"><img src="/images/landing/Screenshot_103.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_104.png" target="_blank"><img src="/images/landing/Screenshot_104.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_105.png" target="_blank"><img src="/images/landing/Screenshot_105.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_106.png" target="_blank"><img src="/images/landing/Screenshot_106.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_107.png" target="_blank"><img src="/images/landing/Screenshot_107.png" alt="feature"></a>
                <a href="/images/landing/Screenshot_108.png" target="_blank"><img src="/images/landing/Screenshot_108.png" alt="feature"></a>
            </VueSlickCarousel>
        </div>
    </div>
</div>
</template>

<script>
import gsap from 'gsap';

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    name: 'SchoolSG',
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: true,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                touchThreshold: 5,
                autoplay: true,
                cssEase: 'linear',
            },
        };
    },
    mounted() {
        // Intersection Observer to detect when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Trigger animation when element enters viewport
                    this.animateHeading();
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe the hero section
        observer.observe(this.$refs.schoolTeamsSection);
    },
    methods: {
        animateHeading() {
            // GSAP Animation
            gsap.from(this.$refs.schoolTeamsCol, {
                duration: 1,
                y: 150,
                opacity: 0,
                delay: 0.5,
                ease: 'power1.inOut',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.school-teams-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 10px;

  .school-teams-col {
    z-index: 50;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    padding: 0 5em 0 5em;

    .first-col {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 30px;
      width: 50%;

      h2 {
        font-size: 3em;
        font-weight: 700;
        margin-bottom: 5px;
      }

      h3 {
        font-size: 2em;
        font-weight: 500;
        margin-bottom: 5px;
      }

      p {
        font-size: 1.2em;
        font-weight: 500;
        color: #666;
        margin-bottom: 20px;
      }
    }

    .second-col {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .school-teams-section {
    .school-teams-col {
      padding: 0 10.5em 0 10.5em;
      .first-col {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .school-teams-section {
    .school-teams-col {
      width: 100%;
      margin-bottom: -100px;
      flex-direction: column;
      padding: 10px;
      .first-col {
        width: 100%;
      }
      .second-col {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .school-teams-section {
    .school-teams-col {
      flex-direction: column;
      .first-col {
        padding: 20px;
        h2 {
          font-size: 2.5em;
        }
        h3 {
          font-size: 1.8em;
        }
        p {
          font-size: 1.2em;
        }
      }
    }
  }
}
</style>
