var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "schoolTeamsSection", staticClass: "school-teams-section" },
    [
      _c("div", { ref: "schoolTeamsCol", staticClass: "school-teams-col" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "second-col" },
          [
            _c(
              "VueSlickCarousel",
              _vm._b({}, "VueSlickCarousel", _vm.settings, false),
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_100.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_100.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_101.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_101.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_102.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_102.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_103.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_103.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_104.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_104.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_105.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_105.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_106.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_106.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_107.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_107.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/images/landing/Screenshot_108.png",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/landing/Screenshot_108.png",
                        alt: "feature",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "first-col" }, [
      _c("h2", [_vm._v("School Teams")]),
      _c("h3", [
        _vm._v("Customize SyncGrades to best meet your unique needs."),
      ]),
      _c("p", [
        _vm._v(
          " Tailor your Sync Grades settings based on your grading system, school needs, and user preferences. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }