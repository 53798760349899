<template>
<div ref="featuresSection" class="outer-section">
    <div id="skew-container" />
    <div
        ref="featuresCol"
        class="inner-section"
        :style="{ maxWidth: 1200 + 'px' }"
    >
        <VueSlickCarousel :key="carouselKey" v-bind="settings">
            <div class="feature-box" :style="{ display: 'flex' }">
                <div class="col-one">
                    <h3>Course Syncing</h3>
                    <p>
                        Eliminate double data entry for teachers through automated data
                        synchronization between Google Classroom™ and your SIS.
                    </p>
                </div>
                <div class="col-two">
                    <img src="/images/landing/feature-one-image.png" alt="feature">
                </div>
            </div>
            <div class="feature-box">
                <div class="col-one">
                    <h3>Analytics</h3>
                    <p>
                        Eliminate double data entry for teachers through automated data
                        synchronization between Google Classroom™ and your SIS.
                    </p>
                </div>
                <div class="col-two">
                    <img src="/images/landing/feature-two-image.png" alt="feature">
                </div>
            </div>
            <div class="feature-box">
                <div class="col-one">
                    <h3>Performance</h3>
                    <p>
                        Innovative administrator accounts and school customization to
                        efficiently support teachers.
                    </p>
                </div>
                <div class="col-two">
                    <img src="/images/landing/feature-three-image.png" alt="feature">
                </div>
            </div>
            <div class="feature-box">
                <div class="col-one">
                    <h3>Control</h3>
                    <p>
                        Innovative administrator accounts and school customization to
                        efficiently support teachers.
                    </p>
                </div>
                <div class="col-two">
                    <img src="/images/landing/feature-four-image.png" alt="feature">
                </div>
            </div>
            <div class="feature-box">
                <div class="col-one">
                    <h3>Report Cards</h3>
                    <p>
                        Streamlined report card tools with teacher report card entry and
                        administrator EGG file extraction
                    </p>
                </div>
                <div class="col-two">
                    <img src="/images/landing/feature-five-image.png" alt="feature">
                </div>
            </div>
        </VueSlickCarousel>
    </div>
</div>
</template>

<script>
import gsap from 'gsap';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    name: 'LandingCarousel',
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: true,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
                touchThreshold: 5,
                autoplay: true,
                cssEase: 'linear',
            },
            carouselKey: 0, // Key to force re-render of VueSlickCarousel
        };
    },
    beforeDestroy() {
        // Remove event listener when component is destroyed
        window.removeEventListener('resize', this.updateSettings);
    },
    mounted() {
        console.log('Mounted hook called');

        // Intersection Observer to detect when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Trigger animation when element enters viewport
                    this.animateHeading();
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe the hero section
        observer.observe(this.$refs.featuresSection);

        this.updateSettings();

        window.addEventListener('resize', this.updateSettings);
    },
    methods: {
        animateHeading() {
            // GSAP Animation
            gsap.from(this.$refs.featuresCol, {
                duration: 1,
                y: 200,
                opacity: 0,
                delay: 0.5,
                ease: 'power1.inOut',
            });
        },

        updateSettings() {
            console.log('Update settings called');
            // Update slidesToShow based on screen width
            const screenWidth = window.innerWidth;
            if (screenWidth < 1024) {
                this.settings.slidesToShow = 1;
            } else {
                this.settings.slidesToShow = 2;
            }
            // Update the key to force re-render of VueSlickCarousel
            this.carouselKey += 1;
        },
    },
};
</script>

<style scoped lang="scss">
.outer-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-track {
  margin-left: -20px !important;
}
.feature-box {
  display: flex !important;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 40px;
  background: #ebefff;
  /* box-shadow: 0 10px 20px #131a331e; */
  border: 20px solid white;
  border-left: 1em solid white;
  border-radius: 1.5em;
  margin: 20px;
  height: 400px;

  .col-one {
    width: 60%;
    h3 {
      font-size: 2.3em;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2em;
      font-weight: 500;
      color: #666;
      margin-bottom: 20px;
    }
  }

  .col-two {
    width: 40%;
    img {
      width: 100%;
      user-select: none;
      pointer-events: none;
    }
  }
}

.feature-box {
  width: 45%;
}

.feature-box[data-v-836d1972] {
  display: flex !important;
}
/* div[data-v="e4caeaf8"] {
  margin: 20px !important;
} */
/*
.slick-slide {
  margin: 20px !important;
} */

.slick-list .slick-slide[data-v-e4caeaf8] {
  margin-right: 40px !important;
  margin-bottom: -200px !important;
}

/* .slick-initialized .slick-slide[data-v-e4caeaf8] {
  margin: 20px;
} */

.slick-dots li button:before {
  font-size: 14px !important;
}

.inner-section {
  padding: 0 7em 0 4.5em;
}

#skew-container {
  position: absolute;
  bottom: -125px;
  left: 0;

  background-color: white;
  height: 250px;
  width: 100%;
  transform: skewY(-13deg) !important;
  /* border: 1px solid red; */
}

@media screen and (max-width: 1024px) {
  .inner-section {
    padding: 0 12em 0 9.5em;
  }
  .feature-box {
    .col-one {
      h3 {
        font-size: 2em;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .outer-section {
    display: none;
  }
  .feature-box {
    flex-direction: column-reverse;
    padding: 20px;
    height: auto;
    .col-one {
      width: 100%;
      h3 {
        font-size: 2em;
      }
    }

    .col-two {
      width: 100%;
      img {
        width: 30%;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .feature-box {
    flex-direction: column-reverse;
    padding: 20px;
    height: auto;
    .col-one {
      width: 100%;
      h3 {
        font-size: 2em;
      }
    }

    .col-two {
      width: 100%;
      img {
        width: 30%;
      }
    }
  }
}
</style>
