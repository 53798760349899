<template>
<div
    ref="manageAssignmentsSection"
    class="manage-assignments-section container-fluid align-items-center"
>
    <!-- <img src="/images/landing/hero-bg-three.png" alt="" /> -->
    <div ref="manageAssignmentsCol" class="manage-assignments-col row">
        <div class="model-box model">
            <h2 class="mb-4 pb-4">
                Welcome to SyncGrades
            </h2>
            <p>
                We are a certified Minority Owned Company dedicated to revolutionizing education by simplifying the complexities of school data management. At SyncGrades, we believe that every student’s success starts with clear, accessible, and actionable data. Our mission is to bridge the gap between siloed data sets and the redundant, labor-intensive systems that educators face daily.
            </p>
            <p>
                By streamlining processes from grading to analytics, we empower teachers, administrators, parents, and students with the tools they need to achieve better educational outcomes. SyncGrades transforms complicated tasks into simple, intuitive experiences, ensuring that every stakeholder can focus on what truly matters—student success.
            </p>
            <p>
                Explore how SyncGrades can make a difference in your school today.
            </p>
        </div>
    </div>
</div>
</template>

<script>
import gsap from 'gsap';

export default {
    name: 'AssignmentSG',
    mounted() {
        // Intersection Observer to detect when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Trigger animation when element enters viewport
                    this.animateHeading();
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe the hero section
        observer.observe(this.$refs.manageAssignmentsSection);
    },
    methods: {
        animateHeading() {
            // GSAP Animation
            gsap.from(this.$refs.manageAssignmentsCol, {
                duration: 1.5,
                y: 50,
                opacity: 0,
                delay: 1,
                ease: 'power1.inOut',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.manage-assignments-section {
  background: url("/images/landing/hero-bg-three.png");
  height: 160vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  position: relative;
  margin: -35vh 0 -35vh 0;
  z-index: 0;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;
  }

  .manage-assignments-col {
    /* transform: rotate(90deg); */
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .model-box {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border: #ebefff;
      background: rgba(152, 169, 255, 0.08);
      padding: 100px 50px;
      border-radius: 2em;

      h2 {
        font-size: 3em;
        font-weight: 700;
        margin-bottom: 5px;
        color: #ebefff;
      }

      p {
        font-size: 1.2em;
        font-weight: 500;
        color: #e2e2e2;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .manage-assignments-section {
    .manage-assignments-col {
      /* transform: rotate(90deg); */
      width: 100%;
      .model-box {
        width: 70%;
        h2 {
          font-size: 2.5em;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .manage-assignments-section {
    padding: 5px;
    img {
      transform: translate(-50%, -50%) scale(1.2);
      width: 150%;
    }
    .manage-assignments-col {
      .model-box {
        padding: 40px;
        width: 90%;
        h2 {
          font-size: 2.2em;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .manage-assignments-section {
    padding: 5px;
    img {
      transform: translate(-50%, -50%) scale(1.8);
    }
    .manage-assignments-col {
      .model-box {
        padding: 20px;
        width: 100%;
        h2 {
          font-size: 1.8em;
        }
      }
    }
  }
}
</style>
