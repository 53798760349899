var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "manageAssignmentsSection",
      staticClass:
        "manage-assignments-section container-fluid align-items-center",
    },
    [
      _c(
        "div",
        {
          ref: "manageAssignmentsCol",
          staticClass: "manage-assignments-col row",
        },
        [_vm._m(0)]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "model-box model" }, [
      _c("h2", { staticClass: "mb-4 pb-4" }, [
        _vm._v(" Welcome to SyncGrades "),
      ]),
      _c("p", [
        _vm._v(
          " We are a certified Minority Owned Company dedicated to revolutionizing education by simplifying the complexities of school data management. At SyncGrades, we believe that every student’s success starts with clear, accessible, and actionable data. Our mission is to bridge the gap between siloed data sets and the redundant, labor-intensive systems that educators face daily. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " By streamlining processes from grading to analytics, we empower teachers, administrators, parents, and students with the tools they need to achieve better educational outcomes. SyncGrades transforms complicated tasks into simple, intuitive experiences, ensuring that every stakeholder can focus on what truly matters—student success. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Explore how SyncGrades can make a difference in your school today. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }