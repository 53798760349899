var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "featuresSection", staticClass: "outer-section" }, [
    _c("div", { attrs: { id: "skew-container" } }),
    _c(
      "div",
      {
        ref: "featuresCol",
        staticClass: "inner-section",
        style: { maxWidth: 1200 + "px" },
      },
      [
        _c(
          "VueSlickCarousel",
          _vm._b(
            { key: _vm.carouselKey },
            "VueSlickCarousel",
            _vm.settings,
            false
          ),
          [
            _c(
              "div",
              { staticClass: "feature-box", style: { display: "flex" } },
              [
                _c("div", { staticClass: "col-one" }, [
                  _c("h3", [_vm._v("Course Syncing")]),
                  _c("p", [
                    _vm._v(
                      " Eliminate double data entry for teachers through automated data synchronization between Google Classroom™ and your SIS. "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-two" }, [
                  _c("img", {
                    attrs: {
                      src: "/images/landing/feature-one-image.png",
                      alt: "feature",
                    },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "feature-box" }, [
              _c("div", { staticClass: "col-one" }, [
                _c("h3", [_vm._v("Analytics")]),
                _c("p", [
                  _vm._v(
                    " Eliminate double data entry for teachers through automated data synchronization between Google Classroom™ and your SIS. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-two" }, [
                _c("img", {
                  attrs: {
                    src: "/images/landing/feature-two-image.png",
                    alt: "feature",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "feature-box" }, [
              _c("div", { staticClass: "col-one" }, [
                _c("h3", [_vm._v("Performance")]),
                _c("p", [
                  _vm._v(
                    " Innovative administrator accounts and school customization to efficiently support teachers. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-two" }, [
                _c("img", {
                  attrs: {
                    src: "/images/landing/feature-three-image.png",
                    alt: "feature",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "feature-box" }, [
              _c("div", { staticClass: "col-one" }, [
                _c("h3", [_vm._v("Control")]),
                _c("p", [
                  _vm._v(
                    " Innovative administrator accounts and school customization to efficiently support teachers. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-two" }, [
                _c("img", {
                  attrs: {
                    src: "/images/landing/feature-four-image.png",
                    alt: "feature",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "feature-box" }, [
              _c("div", { staticClass: "col-one" }, [
                _c("h3", [_vm._v("Report Cards")]),
                _c("p", [
                  _vm._v(
                    " Streamlined report card tools with teacher report card entry and administrator EGG file extraction "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-two" }, [
                _c("img", {
                  attrs: {
                    src: "/images/landing/feature-five-image.png",
                    alt: "feature",
                  },
                }),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }